<template>
  <div class="share-box">
    <div class="backdrop" @click="close"></div>
    <div class="boxs text-end">
      <h2 class="mb-3">{{ $t("shareNews") }}</h2>
      <p class="title">
        {{ sharing.title }}
      </p>
      <p class="text-start link en" dir="ltr">
        {{ sharing.url.slice(0, 40) }}...
      </p>
      <button class="btn-pri" @click="copy">{{ $t("copyLink") }}</button>
      <div class="links">
        <i class="fa fa-times close" @click="close"></i>
        <ShareNetwork
          v-for="network in networks"
          :network="network.network"
          :key="network.network"
          :style="{ color: network.color }"
          :url="sharing.url"
          :title="sharing.title"
          :description="sharing.description.replace(/<\/?[^>]+(>|$)/g, '')"
          :quote="sharing.quote"
          :hashtags="sharing.hashtags"
          :twitterUser="sharing.twitterUser"
        >
          <i :class="[network.icon]"></i>
          <span>{{ network.name }}</span>
        </ShareNetwork>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["sharing"],
  data() {
    return {
      networks: [
        {
          network: "email",
          name: "Email",
          icon: "fa fa-envelope",
          color: "#333333",
        },
        {
          network: "facebook",
          name: "Facebook",
          icon: "fa fa-facebook-f",
          color: "#1877f2",
        },
        {
          network: "linkedin",
          name: "LinkedIn",
          icon: "fa fa-linkedin",
          color: "#007bb5",
        },
        {
          network: "skype",
          name: "Skype",
          icon: "fa fa-skype",
          color: "#00aff0",
        },
        {
          network: "sms",
          name: "SMS",
          icon: "fa fa-comment",
          color: "#333333",
        },
        {
          network: "telegram",
          name: "Telegram",
          icon: "fa fa-telegram",
          color: "#0088cc",
        },
        {
          network: "twitter",
          name: "Twitter",
          icon: "fa fa-twitter",
          color: "#1da1f2",
        },

        {
          network: "whatsapp",
          name: "Whatsapp",
          icon: "fa fa-whatsapp",
          color: "#25d366",
        },
      ],
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    copy(e) {
      navigator.clipboard.writeText(this.sharing.url);
      e.target.innerHTML = `${this.$t(
        "linkCopied"
      )}<i class='fa fa-check'></i>`;
      setTimeout(() => {
        e.target.innerHTML = this.$t("copyLink");
      }, 4000);
    },
  },
};
</script>

<style>
</style>